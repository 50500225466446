$(document).ready(function () {

    function selectTemplate(data, container) {

        var branchId = Number(data.id);
        var city = data.city;
        var cityPart = data.cityPart;
        var address = data.address;
        var atm = Number(data.atm);
        var service = Number(data.service);
        var parking = Number(data.parking);
        var extended = Number(data.extended);

        if (!branchId) {
            return "";
        } else {
            var item = "<div class='city'>" + city + "</div>" +
                "<div class='cityPart'>" + cityPart + "</div>" +
                "<div class='address'>" + address + "</div>";


            if (extended) {
                item += "<div class='extended tooltipTrigger'>" + "" + "</div>";
            } else {
                item += "<div class='placeholder'>" + "&nbsp;" + "</div>";
            }

            if (service) {
                item += "<div class='service tooltipTrigger'>" + "" + "</div>";
            } else {
                item += "<div class='placeholder'>" + "&nbsp;" + "</div>";
            }

            if (parking) {
                item += "<div class='parking tooltipTrigger'>" + "" + "</div>";
            } else {
                item += "<div class='placeholder'>" + "&nbsp;" + "</div>";
            }

            if (atm) {
                item += "<div class='atm tooltipTrigger'>" + "" + "</div>";
            } else {
                item += "<div class='placeholder'>" + "&nbsp;" + "</div>";
            }

            item += "<div class='openingHours tooltipTrigger' data-url='/api/hours/"+ branchId +"/'>" + "" + "</div>";


            return $(item);
        }
    }

    var search;
    var instance = new Mark(".select2-results");

    $(".branches").select2({
        language: "cs",
        minimumInputLength: 3,
        ajax: {
            url: "/api/search/",
            data: function (params) {
                search = params.term;
                return {
                    q: params.term
                };
            },
            processResults: function (data) {
                return {
                    results: $.map(data.branches, function (item) {
                        return {
                            id: item.id,
                            text: item.city,
                            city: item.city,
                            cityPart: item.city_part,
                            address: item.address,
                            atm: item.atm,
                            service: item.service,
                            parking: item.parking,
                            extended: item.extended
                        }
                    })
                };
            },
            complete: function () {
                if (search !== undefined) {
                    setTimeout(function () {
                        instance.mark(search);
                    }, 100);
                }
            }
        },
        templateResult: selectTemplate,
        templateSelection: selectTemplate
    });

    $('select').on('select2:select', function (e) {
        $('.select2-selection__rendered').removeAttr('title');
        $("#callbackForm").find("input[name='branchId']").val($(this).val());
        $("#callbackForm").show();
    });


    $('select').on('select2:close', function (e) {
        $("#tooltip").hide(100);
    });


    $("body").on("mouseover", ".tooltipTrigger", function (e) {
        var url = $(this).data("url");

        if (url !== undefined) {
            $.ajax({
                url: url
            })
                .done(function (data) {
                    $("#tooltip").html(data).show(100);
                })
        } else {
            if ($(this).hasClass("atm")) {
                $("#tooltip").html("Bankomat na poště").show(100);
            } else if ($(this).hasClass("parking")) {
                $("#tooltip").html("Parkoviště u provozovny").show(100);
            } else if ($(this).hasClass("service")) {
                $("#tooltip").html("Příjem balíkových a listovních zásilek a peněžních poukázek").show(100);
            } else if ($(this).hasClass("extended")) {
                $("#tooltip").html("Prodloužená otevírací doba provozovny – po 18.00").show(100);
            }
        }
    });

    $("body").on("mouseout", ".tooltipTrigger", function (e) {
        $("#tooltip").hide(100);
    });

    $("body").on("mousemove", ".tooltipTrigger", function (e) {
        var obj = document.getElementById("tooltip");

        obj.style.top = e.pageY + "px";
        obj.style.left = e.pageX + 12 + "px";
    });


});
/*! Select2 4.0.3 | https://github.com/select2/select2/blob/master/LICENSE.md */

(function(){if(jQuery&&jQuery.fn&&jQuery.fn.select2&&jQuery.fn.select2.amd)var e=jQuery.fn.select2.amd;return e.define("select2/i18n/cs",[],function(){function e(e,t){switch(e){case 2:return t?"dva":"dvě";case 3:return"tři";case 4:return"čtyři"}return""}return{errorLoading:function(){return"Výsledky nemohly být načteny."},inputTooLong:function(t){var n=t.input.length-t.maximum;return n==1?"Prosím zadejte o jeden znak méně":n<=4?"Prosím zadejte o "+e(n,!0)+" znaky méně":"Prosím zadejte o "+n+" znaků méně"},inputTooShort:function(t){var n=t.minimum-t.input.length;return n==1?"Prosím zadejte ještě jeden znak":n<=4?"Prosím zadejte ještě další "+e(n,!0)+" znaky":"Prosím zadejte ještě dalších "+n+" znaků"},loadingMore:function(){return"Načítají se další výsledky…"},maximumSelected:function(t){var n=t.maximum;return n==1?"Můžete zvolit jen jednu položku":n<=4?"Můžete zvolit maximálně "+e(n,!1)+" položky":"Můžete zvolit maximálně "+n+" položek"},noResults:function(){return"Nenalezeny žádné položky"},searching:function(){return"Vyhledávání…"}}}),{define:e.define,require:e.require}})();